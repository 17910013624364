<template>
  <div
    class="shop-products"
    :class="{
      'is-recommendation': data.is_recommendation,
      'is-sticky': data.is_sticky,
      'lift-index': selectFieldOpen,
    }"
  >
    <div class="shop-products__grid">
      <div class="shop-products__head-wrapper">
        <div
          v-if="!data.is_combined"
          class="shop-products__head"
        >
          <div class="shop-products__pretitle">
            <span v-if="data.show_filtered_count">{{ total }}</span>
            {{ data.pretitle }}
          </div>
          <div class="shop-products__title">
            {{ data.title }}
          </div>
        </div>

        <FilterMain
          v-if="data?.main_filter?.length === 1"
          :counts="counts"
          :tabs="data.main_filter[0].tabs"
          :is-hidden="data.hide_main_filter"
          @set-filter-types="setFilterTypes"
          @set-active-main-filter-name="setActiveMainFilterName"
        />
      </div>

      <FilterSecondary
        v-if="data?.secondary_filter.length === 1"
        :data="data.secondary_filter[0]"
        :prefill-value="data.secondary_filter[0].prefill_value"
        :is-hidden="data.hide_secondary_filter"
        @set-filter-relationals="setFilterRelationals"
      />

      <div class="shop-products__sticky-container">
        <FilterText
          v-if="!useGlobalAdvancedFilter && data?.text_filter.length === 1"
          :data="data.text_filter[0]"
          :is-hidden="data.hide_text_filter"
          @set-text-search="setFilterTextSearch"
        />

        <FilterAdvanced
          v-if="!useGlobalAdvancedFilter && data?.advanced_filter.length === 1"
          :data="data.advanced_filter[0]"
          :is-hidden="data.hide_advanced_filter"
          @set-filter-advanced="setFilterAdvanced"
          @set-availability="setAvailability"
        />
        <div class="shop-products-sticky__background" />
      </div>

      <ShopProductRenderer :products="items" />

      <div
        v-if="data.show_load_more"
        class="shop-products__btn-load-more"
      >
        <AtomLoadMore
          v-if="counts"
          :button-text="loadMoreText"
          :max-item-amount="counts[activeMainFilterName]"
          :current-item-amount="items?.length"
          :is-loading="false"
          @load-more="getItems(true)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
    useGlobalAdvancedFilter: {
        type: Boolean,
        default: false,
    },
    globalFilterData: {
        type: Object,
        default: () => {},
    },
});

const route = useRoute();

const counts = ref({});
const pageSize = computed(() => parseInt(props.data.pagination_size, 10));
const isPopupDisabled = computed(() => props.data.disable_product_popup);
provide('is-popup-disabled', isPopupDisabled);
const selectFieldPopupStore = useSelectFieldPopupStore();
const selectFieldOpen = computed(() => selectFieldPopupStore.isOpen);

const rawQuery = reactive({
    page: 1,
    pageSize: pageSize.value || 6,
    onlyRecommended: props.data.is_recommendation,
    availability: 'all',
    types: [],
    countGroups: [],
    relationalByIds: null,
    relationalByText: {
        searchString: '',
        additional_relations: {},
    },
    relationalByName: null,
});

/* Set count groups to rawQuery */
if (props.data.main_filter?.length === 1) {
    const mapped = props.data.main_filter[0].tabs?.map(
        (tab) => ({
            name: tab.name,
            types: tab.types.map((flatten) => flatten),
        }),
    );

    mapped.forEach((item) => {
        rawQuery.countGroups[item.name] = item.types;
    });
}

/* Prepare text search object */
if (props.data.text_filter?.length === 1 && !props.useGlobalAdvancedFilter) {
    const flattened = props.data.text_filter[0].additional_relational_search?.map(
        (item) => item,
    );

    rawQuery.relationalByText.additional_relations = flattened;
}

const computedQuery = computed(() => useQueryString(rawQuery));

/*
    Get Items
*/
const items = ref([]);
const total = ref(0);
const strapiClient = useStrapiClient();
const { sendEvent } = useGTM();

const getItems = async (merge = false) => {
    try {
        rawQuery.page = merge ? rawQuery.page + 1 : 1;

        const res = await strapiClient(`shop-items/get-items-by-filters?${computedQuery.value}`, {
            method: 'GET',
        });

        items.value = merge ? [...items.value, ...res.items] : res.items;

        if (rawQuery?.relationalByText?.searchString) {
            sendEvent({
                event: 'view_search_results',
                search_term: computedQuery.value,
            });
        }

        if (merge) {
            sendEvent({
                event: 'view_item_list',
                ecommerce: {
                    currency: 'CHF',
                    value: null,
                    items: items?.value.map((product) => ({
                        id: product.id,
                        name: product.name,
                        pharma_code: product.pharma_code,
                        price: product.price,
                        is_sendable: product.is_sendable || false,
                        needs_prescription: product.needs_prescription || false,
                        short_text: product.short_text,
                        description: product.description,
                    })),
                },
            });
        }

        counts.value = res.countsWithKey;
        total.value = res.totalCount;
    } catch (e) {
        useSentryError(e);
    }
};

/*
    Utils
*/
const resetCurrentPage = () => {
    rawQuery.page = 1;
};

/*
    Emit Handling
*/
const setFilterTypes = (data) => {
    resetCurrentPage();
    const flattened = data.values.map((item) => item);
    rawQuery.types = [];
    rawQuery.types = flattened;
    if (data.isInitial) return;
    getItems(false);
};

const setFilterRelationals = (data) => {
    rawQuery.relationalByIds = data.values;
    if (data.isInitial) return;
    getItems(false);
};

const setFilterTextSearch = (data) => {
    const values = typeof data.values === 'string' ? data.values : '';
    rawQuery.relationalByText.searchString = values;
    if (data.isInitial) return;
    getItems(false);
};

const setFilterAdvanced = (data) => {
    rawQuery.relationalByName = data.values;
    if (data.isInitial) return;
    getItems(false);
};

const setAvailability = (data) => {
    rawQuery.availability = data.values;
    if (data.isInitial) return;
    getItems(false);
};

const activeMainFilterName = ref('');
const setActiveMainFilterName = (data) => {
    activeMainFilterName.value = data;
};

/*
    Watcher for global advanced filter
*/
if (props.useGlobalAdvancedFilter) {
    watch(() => props.globalFilterData, (newValue) => {
        setAvailability({ values: newValue.availability, isInitial: true });
        setFilterAdvanced({ values: newValue.relationalByName, isInitial: true });
        setFilterTextSearch({ values: newValue.relationalByText.searchString, isInitial: true });

        const additionalRelations = newValue.relationalByText.additional_relations;
        rawQuery.relationalByText.additional_relations = additionalRelations;

        getItems();
    }, { deep: true });
}

/*
      Load More
  */
const loadMoreText = computed(() => (props.data.is_recommendation === true
    ? useTranslation('general', 'load_more_recommendations')
    : useTranslation('general', 'load_more')
));

onMounted(() => {
    if (!props.useGlobalAdvancedFilter) {
        getItems();
    }
});

/*
    Watcher for storyblok
  */
if (route.query._storyblok) {
    watch(() => props.data.is_recommendation, () => {
        getItems();
    });

    watch(() => pageSize.value, () => {
        getItems();
    });
}
</script>

<style lang="scss" scoped>
.shop-products {
    @include grid-full();
    @include fluid('margin-bottom', 60px, 120px);

    &.is-recommendation {
        background-color: $C_GREEN_BRIGHT;
        border-bottom-right-radius: 50px;
        border-top-left-radius: 50px;
    }

}

.shop-products__grid {
    @include grid-layout();

    width: 100%;
    max-width: var(--grid-width);
    padding: 50px var(--page-padding);
    margin: 0 auto;
}

.shop-products__sticky-container {
    @include grid-columns();

    .is-sticky & {
        position: sticky;
        z-index: 20;
        top: var(--headerHeight);
        bottom: 10px;
        padding-top: 40px;
        background: $C_WHITE;

        & + .shop-product-renderer {
            @include fluid('margin-top', 26px, 114px);
        }
    }

    .lift-index {
        z-index: 150;
    }

}

.shop-products-sticky__background {
    .is-sticky & {
        @include grid-full;
        position: absolute;
        z-index: -1;
        top: 0;
        height: 100%;
        box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.05);
        pointer-events: none;
    }
}

.shop-products__head-wrapper {
    @include grid-columns();
    @include fluid('margin-bottom', 14px, 30px);

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @include tablet-portrait {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 21px;
    }
}

.shop-products__head {
    position: relative;
    min-width: 50%;
    color: $C_GREEN_DARK;
}

.shop-products__pretitle {
    @include typo-size('p');
    @include typo-font('light');

    margin-bottom: 6px;

    @include mobile {
        margin-bottom: 0;
    }
}

.shop-products__title {
    @include typo-size('h2');
    @include typo-font('regular');
}

.shop-products__product-wrapper {
    @include grid-layout();
    @include grid-columns(1, var(--grid-columns));
    @include fluid('margin-bottom', 20px, 40px);

    width: 100%;
    max-width: var(--grid-width);
    row-gap: var(--grid-gutter);
}

.shop-products__btn-load-more {
    @include grid-columns(1, var(--grid-columns));

    display: flex;
    justify-content: center;
    margin-top: 10px;
}
</style>
